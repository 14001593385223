import "./App.css";
import React, { useEffect } from 'react';
import {Route, Routes, useNavigate} from 'react-router-dom';
import { ProtectedRoute } from "./routes";
import AuthCheck from "./utils/authCheck";

const HomePage = React.lazy(()=> import('./pages/home'));
const LoginPage = React.lazy(()=> import('./pages/login'));
const OrganizationPage = React.lazy(()=> import('./pages/organization/organizationList'))
const OrganizationAddPage = React.lazy(()=> import('./pages/organization/organizationAdd'))
const OrganizationEditPage = React.lazy(()=> import('./pages/organization/organizationEdit'))
const UserPage = React.lazy(()=> import('./pages/users/usersList'))
const UserAddPage = React.lazy(()=> import('./pages/users/usersAdd'))
const UserEditPage = React.lazy(()=> import('./pages/users/usersEdit'))
const CategoryPage = React.lazy(()=> import('./pages/categories/categoryList'))
const CategoryAddPage = React.lazy(()=> import('./pages/categories/categoryAdd'))
const CategoryEditPage = React.lazy(()=> import('./pages/categories/categoryEdit'))
const ProductPage = React.lazy(()=> import('./pages/products/productsList'))
const ProductAddPage = React.lazy(()=> import('./pages/products/productAdd'))
const ProductEditPage = React.lazy(()=> import('./pages/products/productEdit'))
const ProductShowPage = React.lazy(()=> import('./pages/products/productShow'))
const InvoicePage = React.lazy(()=> import('./pages/invoice/invoiceAdd'))
const OrdersViewPage = React.lazy(()=> import('./pages/invoice/orderList'))
const StockPage = React.lazy(()=> import('./pages/stock'))
const UserStatsPage = React.lazy(()=> import('./pages/users/userStats'))
const GeneralReportPage = React.lazy(()=> import('./pages/reports'))
const StoreProductPage = React.lazy(()=> import('./pages/Inventory/storeProducts'))
const StoreProductAddPage = React.lazy(()=> import('./pages/Inventory/storeProductAdd'))
const ExpensesPage = React.lazy(()=> import('./pages/Inventory/expenses'))
const ExpensesAddPage = React.lazy(()=> import('./pages/Inventory/expensesAdd'))
const ErrorPage = React.lazy(()=> import('./pages/error'))

const App = () => {
  const router = useNavigate()

  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === 'token' || e.key === 'full_name' || e.key === 'role') {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('full_name');
        sessionStorage.removeItem('role');
        router('/login')
        return
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  },[])

  return (
    <Routes>
      <Route element={<ProtectedRoute />}>
        <Route element={<AuthCheck userRole={['manager', 'super-admin']}/>}>
          <Route path='/' element={<HomePage/>}></Route>
          <Route path='/users/list' element={<UserPage/>}></Route>
          <Route path='/users/add' element={<UserAddPage/>}></Route>
          <Route path='/users/edit/:userId' element={<UserEditPage/>}></Route>
        </Route>
        <Route element={<AuthCheck userRole={['manager']}/>}>
          <Route path='/order/stats' element={<UserStatsPage/>}></Route>
        </Route>
        <Route element={<AuthCheck userRole={['manager', 'cashier', 'waiter']}/>}>          
          <Route path='/invoice' element={<InvoicePage/>}></Route>
          <Route path='/orders/list' element={<OrdersViewPage/>}></Route>
          <Route path='/products/list' element={<ProductPage/>}></Route>
          <Route path='/category/list' element={<CategoryPage/>}></Route>
          <Route path="/stock" element={<StockPage/>}></Route>
          <Route path="/store/product/list" element={<StoreProductPage/>}></Route>
          <Route path="/store/product/add" element={<StoreProductAddPage/>}></Route>
          <Route path="/expenses" element={<ExpensesPage/>}></Route>
          <Route path="/expenses/add" element={<ExpensesAddPage/>}></Route>
        </Route>
        
        <Route element={<AuthCheck userRole={['manager']}/>}>
          <Route path='/reports' element={<GeneralReportPage/>}></Route>
          <Route path='/products/add' element={<ProductAddPage/>}></Route>
          <Route path='/products/edit/:productId' element={<ProductEditPage/>}></Route>
          <Route path='/products/show/:productId' element={<ProductShowPage/>}></Route>
          <Route path='/category/add' element={<CategoryAddPage/>}></Route>
          <Route path='/category/edit/:categoryId' element={<CategoryEditPage/>}></Route>
        </Route>
        
        <Route element={<AuthCheck userRole={['super-admin']}/>}>
          <Route path='/organization/list' element={<OrganizationPage/>}></Route>
          <Route path='/organization/add' element={<OrganizationAddPage/>}></Route>
          <Route path='/organization/edit/:organizationId' element={<OrganizationEditPage/>}></Route>
        </Route>
      </Route>
      
      <Route path="/login" element={<LoginPage/>}></Route>
      <Route path="/error" element={<ErrorPage/>}></Route>
    </Routes>
  );
}

export default App;
